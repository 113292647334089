export const themeLightScrollStyle = {
  '&::-webkit-scrollbar-track': {
    borderRadius: '10px',
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
  },
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px',
    borderRadius: '10px',
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '10px',
    backgroundColor: 'rgba(85, 85, 85, 0.31)',
  }
};

export const themeDarkScrollStyle = {
  '&::-webkit-scrollbar-track': {
    borderRadius: '10px',
    backgroundColor: '#071426',
  },
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px',
    borderRadius: '10px',
    backgroundColor: '#071426',
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '10px',
    backgroundColor: '#0b213f',
  }
};
