import { createTheme } from '@mui/material/styles';

import { themeComponents } from '@/shared/consts/themes/components';
import { themeRootStyles } from '@/shared/consts/themes/root';

export const mainLightTheme = createTheme({
  ...themeRootStyles,
  components: themeComponents,
  palette: {
    mode: 'light',
    background: {
      paper: '#fff',
      default: '#eff3f8',
    },
    primary: {
      main: '#57A5F1',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#d84315',
      light: '#ff7543',
      dark: '#9f0000',
    },
    icons: {
      main: '#ff0000',
      light: '#ff0000',
      dark: '#ff0000',
    },
    border: {
      main: 'rgba(0,0,0,0.23)',
      light: '#ff0000',
      dark: '#ff0000',
    },
    text: {
      primary: '#343a40',
      secondary: '#858a8d',
    },
    table: {
      tableBg: '#F7FAFC',
      tableBorder: '#E2E8F0',
    }
  }
});
