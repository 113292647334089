import { createTheme } from '@mui/material/styles';

import { themeComponents } from '@/shared/consts/themes/components';
import { themeRootStyles } from '@/shared/consts/themes/root';


export const mainDarkTheme = createTheme({
  ...themeRootStyles,
  components: themeComponents,
  palette: {
    mode: 'dark',
    background: {
      paper: '#071426',
      default: '#040d19',
    },
    primary: {
      main: '#57A5F1',
      contrastText: '#000000',
    },
    secondary: {
      main: '#d84315',
      light: '#ff7543',
      dark: '#9f0000',
    },
    icons: {
      main: '#ff0000',
      light: '#ff0000',
      dark: '#ff0000',
    },
    border: {
      main: '#0b213f',
      light: '#ff0000',
      dark: '#ff0000',
    },
    text: {
      primary: 'rgba(255, 255, 255, 0.87)',
      secondary: 'rgba(255, 255, 255, 0.6)',
    },
    table: {
      tableBg: '#071426',
      tableBorder: '#0b213f',
    }
  }
});
