import { OIDCAuthController } from '@proapteka/oidc-auth-controller';
import axios from 'axios';
import queryString from 'query-string';

import { apiUrl } from '@/app/env/env.config';

export const httpCore = axios.create({
  withCredentials: true,
  paramsSerializer: params => {
    return queryString.stringify(params);
  },
  baseURL: apiUrl,
  timeout: 60 * 1000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

//////////////////////////////////////////////////////////////////////

export const authController = new OIDCAuthController({ Axios: httpCore, });

authController.addEventListener('authStatus', (status: boolean) => {
  // store.dispatch({ type: 'AUTH/setCheckingLogin', payload: status })
});

authController.addEventListener('error', (status: boolean) => {
  // store.dispatch({ type: 'AUTH/setClosedLogin', payload: status })
});

//////////////////////////////////////////////////////////////////////
