import { Components, Theme } from '@mui/material';

import { themeLightScrollStyle } from '@/shared/consts/themes/scrollStyle';


export const themeComponents: Components<Omit<Theme, 'components'>> | undefined = {

  MuiOutlinedInput: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: '6px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '1rem',
        fontWeight: 400,
        background: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.background.paper,
        ' .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.border.main,
          transition: 'background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s'
        },
        '&:hover > .MuiOutlinedInput-notchedOutline': { borderColor: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.23)' : theme.palette.primary.main }
      }),
    },
    defaultProps: { size: 'small' }
  },
  MuiFormControl: {
    styleOverrides: {
      root: ({ theme }) => ({
        '.MuiFormLabel-root': { color: theme.palette.grey[ '600' ] },
        '& :hover > .MuiOutlinedInput-notchedOutline': { borderColor: theme.palette.primary.main },
        '& .MuiInputBase-root.MuiInputBase-hiddenLabel': { marginTop: 0 },
      })
    },
    defaultProps: { size: 'small' }
  },
  MuiModal: { styleOverrides: { root: ({ theme }) => ({ ' .MuiPaper-root': { background: theme.palette.background.paper } }) } },
  MuiButton: {
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        borderRadius: '6px',
        textTransform: 'none',
        boxShadow: 'none',
        height: '40px',
        fontSize: '0.8rem',
        fontWeight: 500,
        lineHeight: '15px',
        color: ownerState.variant === 'contained' ? theme.palette.getContrastText(theme.palette.text.primary) : theme.palette.primary.main,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        ':hover': { boxShadow: 'none', }
      }),
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        '&:not(.MuiDialog-paperFullScreen)': {
          borderRadius: '10px',
          maxWidth: '95%',
          maxHeight: '70%',
        }
      },
    }
  },
  MuiAlert: {
    styleOverrides: {
      root: () => ({
        borderRadius: '6px',
        alignItems: 'center',
        justifyContent: 'center',
      })
    }
  },
  MuiAutocomplete: {
    styleOverrides: {
      listbox: ({ theme }) => ({
        ...themeLightScrollStyle,
        ' li>.MuiStack-root .MuiBox-root': {
          color: theme.palette.text.secondary,
          ' b': { color: theme.palette.text.secondary },
          ':first-of-type': { color: theme.palette.text.primary, }
        },
      }),
      root: ({ theme }) => ({
        '.MuiFormLabel-root': { color: theme.palette.text.secondary },
        '& :hover > .MuiOutlinedInput-notchedOutline': { borderColor: theme.palette.primary.main },
      })
    },
    defaultProps: { size: 'small' }
  },
  MuiDrawer: { styleOverrides: { paper: themeLightScrollStyle, } },
  MuiTableContainer: { styleOverrides: { root: themeLightScrollStyle } },
  MuiStack: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    styleOverrides: { root: themeLightScrollStyle, }
  },
  MuiPaper: { styleOverrides: { root: themeLightScrollStyle } },
  MuiList: { styleOverrides: { root: themeLightScrollStyle } },
  MuiDivider: { styleOverrides: { root: ({ theme }) => ({ borderColor: theme.palette.border.main, }) } },
  MuiTypography: { styleOverrides: { root: ({ theme }) => ({ color: theme.palette.text.primary }) } }
};
