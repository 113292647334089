import './styles/reset.css';
import { Stack } from '@mui/material';
import { OIDCAuthIFrame } from '@proapteka/oidc-auth-iframe';
import { lazy } from 'react';

import { authController } from '@/shared/axios/requester';
import { Loader } from '@/shared/ui/Loader/Loader';

const AppRouter = lazy(() => import('./routes/AppRouter'));

function App() {
  const LoaderFullScreen = (
    <Stack width={'100%'} height={'100vh'} justifyContent={'center'} alignItems={'center'}>
      <Loader/>
    </Stack>
  );

  return (
    <OIDCAuthIFrame authControllerInstance={authController} preloader={LoaderFullScreen}>
      <AppRouter />
    </OIDCAuthIFrame>
  );
}

export default App;
