import { CSSProperties } from 'react';

export const themeRootStyles = { typography: { fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"', }, };

declare module '@mui/material/styles' {
  interface Palette {
    table: Record<string, CSSProperties['color']>
    icons: {
      light: CSSProperties['color']
      main: CSSProperties['color']
      dark: CSSProperties['color']
    }
    border: {
      light: CSSProperties['color']
      main: CSSProperties['color']
      dark: CSSProperties['color']
    }
  }

  interface PaletteOptions {
    table: Record<string, CSSProperties['color']>
    icons: {
      light: CSSProperties['color']
      main: CSSProperties['color']
      dark: CSSProperties['color']
    }
    border: {
      light: CSSProperties['color']
      main: CSSProperties['color']
      dark: CSSProperties['color']
    }
  }

  interface Shadow {
    base: string;
  }
}
